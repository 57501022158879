.hls-tab-host {
  background: inherit;

  .hls-tab-headers {
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;

    min-height: 50px;

    border-bottom: 1px solid #E5E5E5;
    background-color: #FFFFFF;

    line-height: 50px;
    vertical-align: middle;

    .hls-tab-header {
      min-width: 160px;
      padding: 0 16px;

      border-bottom: 3px solid transparent;
      color: #808080;

      text-align: center;
      cursor: pointer;

      &:hover:not(&--active) {
        border-color: #1C8DC6;
        color: #2277BC;
        line-height: 50px;
      }

      &--active {
        border-color: #005AA0;
        background-color: #E1F0F6;
        color: #005AA0;
        line-height: 50px;
      }
    }
  }
}
