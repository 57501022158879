@import "base/elevations";

@import "base/reset";

@import "base/typeset";

@import "base/controls";

@import "config";

@import "shame";

@for $i from 0 through 20 {
  .width-#{$i*5} {
    width: #{$i*5};
  }
}

.hls-d-flex {
  display: flex;
}

.hls-d-flex-2-0 {
  flex: 2 0;
}
